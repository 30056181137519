import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoticeModalModel } from '@app/shared/models/notice.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { NoticeModalComponent } from '../components/notice-modal/notice-modal.component';

@Injectable({
    providedIn: 'root',
})
export class NoticeModalService {
    constructor(
        private matDialog: MatDialog,
        private translateService: TranslateService,
        private dialogService: DialogService
    ) {}

    isModalOpen = false;
    noticesToShow: NoticeModalModel[] = [];

    async showNoticesModal(notices: NoticeModalModel[]): Promise<void> {
        const newNotices = notices.filter(n => n.lockScreen);

        if (newNotices.length === 0) {
            return;
        }

        this.noticesToShow = [...this.noticesToShow, ...newNotices];

        if (!this.isModalOpen) {
            this.showModal(this.noticesToShow.pop());
        }
    }

    async showModal(notice: NoticeModalModel): Promise<void> {
        if (!notice) {
            return;
        }

        this.isModalOpen = true;
        this.matDialog
            .open(NoticeModalComponent, {
                data: { ...notice },
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => {
                if (this.noticesToShow.length === 0) {
                    this.isModalOpen = false;
                } else {
                    this.showModal(this.noticesToShow.pop());
                }
            });
    }
}

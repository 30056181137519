import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from '@app/shared/services/auth.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';

import { TableColTypes } from '@app/core/utils/enums/table-col-types.enum';
import { CustomTableColumnModel } from '@app/shared/models/global-table.model';
import {
    DefaultAlternateModel,
    EmployeeModel,
    ScopeEditorModel,
    UserSettingsModel,
} from '@app/shared/models/user-settings.model';
import { ScopeEditorService } from '@app/shared/services/api/scope-editor.service';
import { UserSettingsService } from '@app/shared/services/api/user-settings.service';
import { UserService } from '@app/shared/services/api/user.service';
import { UserSettingsTabsEnum } from './user-settings-tabs.enum';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
    constructor(
        private systemMessageService: SystemMessageService,
        private translateService: TranslateService,
        private authService: AuthService,
        private userSettingsRepository: UserSettingsService,
        private userRepository: UserService,
        private scopeEditorService: ScopeEditorService
    ) {}

    employees: EmployeeModel[] = [];
    registeredAlternates: UserSettingsModel[] = [];
    alternatesSearch: UserSettingsModel[] = [];
    tableColumns: CustomTableColumnModel[];
    tableColumnsSearch: CustomTableColumnModel[];
    alternateEmployeeForm = new FormGroup({
        employeeId: new FormControl(null),
        name: new FormControl(null),
    });
    alternateEmployeeSearchForm = new FormGroup({
        employeeIdSearch: new FormControl(null),
        nameSearch: new FormControl(null),
    });
    withoutAlternateEmployeeForm = new FormGroup({
        withoutAlternateEmployee: new FormControl(false),
    });
    alternateSearchMessage = '';
    isLoading = true;

    scopeEditorForm = new FormGroup({
        employeeId: new FormControl(null),
        name: new FormControl(null),
    });
    withoutScopeEditor = true;
    registeredScopeEditors: ScopeEditorModel[];
    scopeEditorIsLoading = false;
    scopeEditorTableColumns: CustomTableColumnModel[] = [];

    private _loggedEmployeeId: string;

    ngOnInit(): void {
        this._loggedEmployeeId = this.authService.getEmployeeId();
        this.setTableColumns();
        this.setTableColumnsSearch();
        this.getDefaultAlternate();
    }

    setTableColumns(): void {
        this.tableColumns = [
            new CustomTableColumnModel({
                field: 'deleteAction',
                type: TableColTypes.Remove,
                sortable: false,
                reorderable: false,
            }),
            new CustomTableColumnModel({
                field: 'employeeId',
                header: 'DEFAULT_TEXT.Registration',
            }),
            new CustomTableColumnModel({ field: 'name', header: 'DEFAULT_TEXT.Name' }),
            new CustomTableColumnModel({
                field: 'roles',
                header: 'DEFAULT_TEXT.Profile',
                type: TableColTypes.Text,
                sortable: false,
                reorderable: false,
            }),
            new CustomTableColumnModel({
                field: 'createdAt',
                header: 'DEFAULT_TEXT.IncludedAt',
                type: TableColTypes.Date,
            }),
        ];
    }

    setTableColumnsSearch(): void {
        this.tableColumnsSearch = [
            new CustomTableColumnModel({
                field: 'employeeId',
                header: 'DEFAULT_TEXT.Registration',
            }),
            new CustomTableColumnModel({ field: 'name', header: 'DEFAULT_TEXT.Name' }),
            new CustomTableColumnModel({
                field: 'roles',
                header: 'DEFAULT_TEXT.Profile',
                type: TableColTypes.Text,
                sortable: false,
                reorderable: false,
            }),
            new CustomTableColumnModel({
                field: 'createdAt',
                header: 'DEFAULT_TEXT.IncludedAt',
                type: TableColTypes.Date,
            }),
        ];
    }

    setScopeEditorTableColumns(): void {
        this.scopeEditorTableColumns = [
            new CustomTableColumnModel({
                field: 'deleteAction',
                type: TableColTypes.Remove,
                sortable: false,
                reorderable: false,
            }),
            new CustomTableColumnModel({ field: 'user', header: 'DEFAULT_TEXT.Registration' }),
            new CustomTableColumnModel({ field: 'name', header: 'DEFAULT_TEXT.Name' }),
            new CustomTableColumnModel({
                field: 'role',
                header: 'DEFAULT_TEXT.Profile',
                type: TableColTypes.Text,
                sortable: false,
                reorderable: false,
            }),
            new CustomTableColumnModel({
                field: 'createdAt',
                header: 'DEFAULT_TEXT.IncludedAt',
                type: TableColTypes.Date,
            }),
        ];
    }

    clearAutocomplete(): void {
        this.alternateEmployeeForm.get('employeeId').setValue(null);
        this.alternateEmployeeForm.get('name').setValue(null);
        this.alternateEmployeeSearchForm.get('employeeIdSearch').setValue(null);
        this.alternateEmployeeSearchForm.get('nameSearch').setValue(null);
        this.scopeEditorForm.get('employeeId').setValue(null);
        this.scopeEditorForm.get('name').setValue(null);
    }

    onCheckWithoutAlternateEmployeeHandler(event): void {
        if (event.checked) {
            this.clearAutocomplete();
        }
    }

    searchEmployeeId(employeeId: string): void {
        this.userRepository.getAll({ employeeId }).subscribe(data => {
            return (this.employees = data);
        });
    }

    searchName(name: string): void {
        this.userRepository.getAll({ name }).subscribe(data => {
            return (this.employees = data);
        });
    }

    onSelectEmployeeId(employee: EmployeeModel, standardAlternate = false): void {
        this.alternateEmployeeForm.controls['name'].setValue(employee);
        this.alternateEmployeeSearchForm.controls['nameSearch'].setValue(employee);
        this.scopeEditorForm.controls.name.setValue(employee);

        if (standardAlternate) {
            this.searchDefaultAlternate(employee.employeeID);
        }
    }

    onSelectName(employee: EmployeeModel, standardAlternate = false): void {
        this.alternateEmployeeForm.controls['employeeId'].setValue(employee);
        this.alternateEmployeeSearchForm.controls['employeeIdSearch'].setValue(employee);
        this.scopeEditorForm.controls.employeeId.setValue(employee);

        if (standardAlternate) {
            this.searchDefaultAlternate(employee.employeeID);
        }
    }

    async searchDefaultAlternate(employeeId: string): Promise<void> {
        this.alternateSearchMessage = '';
        this.alternatesSearch = [];
        this.userSettingsRepository.getDefaultAlternate(employeeId).subscribe(async data => {
            if (data.withoutAlternateEmployee) {
                this.alternateSearchMessage = await this.translateService.getTranslation(
                    'SETTINGS_PAGE.SearchWithoutDefaultAlternate'
                );
            } else if (data.defaultAlternates.length === 0) {
                this.alternateSearchMessage = await this.translateService.getTranslation(
                    'SETTINGS_PAGE.SearchNoDefaultAlternate'
                );
            } else {
                this.alternatesSearch = this.formatAlternates(data.defaultAlternates);
            }
        });
    }

    removeAlternate(alternate: UserSettingsModel): void {
        this.userSettingsRepository
            .removeAlternate({ alternateEmployee: alternate.employeeId })
            .subscribe(async () => {
                const message = await this.translateService.getTranslation(
                    'DEFAULT_TEXT.SuccessOperation'
                );
                this.systemMessageService.notifySuccess(message);
                this.getDefaultAlternate();
            });
    }

    changeTabView(selectedTabIndex: number): void {
        this.clearAutocomplete();
        this.alternatesSearch = [];
        if (
            selectedTabIndex === UserSettingsTabsEnum.ScopeEditorForm &&
            !this.registeredScopeEditors
        ) {
            this.scopeEditorIsLoading = true;
            this.getUserScopeEditors();
            this.setScopeEditorTableColumns();
        }
    }

    formatAlternates(alternates: DefaultAlternateModel[]): UserSettingsModel[] {
        return alternates.map(employee => ({
            employeeId: employee.employeeId.toUpperCase(),
            name: employee.name,
            roles: employee.roles.join(', '),
            createdAt: employee.createdAt,
        }));
    }

    getDefaultAlternate(): void {
        this.userSettingsRepository.getDefaultAlternate().subscribe(alternate => {
            this.isLoading = false;
            if (alternate) {
                this.withoutAlternateEmployeeForm.controls['withoutAlternateEmployee'].setValue(
                    alternate.withoutAlternateEmployee
                );

                this.clearAutocomplete();
                this.registeredAlternates = this.formatAlternates(alternate.defaultAlternates);
            }
        });
    }

    async setWithoutAlternate(): Promise<void> {
        this.userSettingsRepository.setWithoutAlternate().subscribe(async () => {
            const message = await this.translateService.getTranslation(
                'DEFAULT_TEXT.SuccessOperation'
            );
            this.systemMessageService.notifySuccess(message);
            this.getDefaultAlternate();
        });
    }

    async submitWithoutAlternateEmployeeForm(): Promise<void> {
        if (this.withoutAlternateEmployeeForm.valid) {
            this.setWithoutAlternate();
        }
    }

    async submitAlternateEmployeeForm(): Promise<void> {
        if (this.alternateEmployeeForm.valid) {
            const alternate = this.alternateEmployeeForm.controls['employeeId'].value;

            const payload = {
                alternateEmployee: alternate.employeeID,
            };

            if (payload.alternateEmployee === this._loggedEmployeeId) {
                this.systemMessageService.notifyWarning(
                    await this.translateService.getTranslation(
                        'GLOBAL.DefaultAlternateInvalidMessage'
                    )
                );
                return;
            }

            this.userSettingsRepository.addAlternate(payload).subscribe(async () => {
                this.systemMessageService.notifySuccess(
                    await this.translateService.getTranslation('DEFAULT_TEXT.SuccessOperation')
                );
                this.getDefaultAlternate();
            });
        }
    }

    clearScopeEditors(): void {
        this.scopeEditorService
            .deleteAllScopeEditors(this._loggedEmployeeId)
            .subscribe(async () => {
                this.systemMessageService.notifySuccess(
                    await this.translateService.getTranslation('DEFAULT_TEXT.SuccessOperation')
                );
                this.registeredScopeEditors = [];
            });
    }

    async submitScopeEditorForm(): Promise<void> {
        if (this.scopeEditorForm.valid) {
            const scopeEditorUser = this.scopeEditorForm.controls.employeeId.value.employeeID;

            if (scopeEditorUser === this._loggedEmployeeId) {
                this.systemMessageService.notifyWarning(
                    await this.translateService.getTranslation(
                        'SETTINGS_PAGE.ScopeEditor.InvalidScopeEditorError'
                    )
                );
                return;
            }

            this.scopeEditorService.postScopeEditor(scopeEditorUser).subscribe(async () => {
                this.systemMessageService.notifySuccess(
                    await this.translateService.getTranslation('DEFAULT_TEXT.SuccessOperation')
                );
                this.getUserScopeEditors();
                this.scopeEditorForm.reset();
            });
        }
    }

    removeScopeEditor(employee: ScopeEditorModel): void {
        this.scopeEditorService.deleteScopeEditor(employee.id).subscribe(async () => {
            this.systemMessageService.notifySuccess(
                await this.translateService.getTranslation('DEFAULT_TEXT.SuccessOperation')
            );
            this.registeredScopeEditors = this.registeredScopeEditors.filter(
                scopeEditor => scopeEditor.id !== employee.id
            );
        });
    }

    getUserScopeEditors(): void {
        this.scopeEditorService.getUserScopeEditors(this._loggedEmployeeId).subscribe(data => {
            this.registeredScopeEditors = data;
            this.withoutScopeEditor = data.length === 0;
            this.scopeEditorIsLoading = false;
        });
    }
}

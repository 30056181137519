import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { SCOPE_EDITOR_URL } from '@app/core/constants/urls/scope-editor.url';
import { USER_URL } from '@app/core/constants/urls/user.url';
import { ApiResult } from '@app/shared/models/api-result.model';
import { ScopeEditorModel } from '@app/shared/models/user-settings.model';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { HttpClient } from '@utils/helpers/HttpClient';

@Injectable({
    providedIn: 'root',
})
export class ScopeEditorService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getUserScopeEditors(employeeId: string): Observable<ScopeEditorModel[]> {
        return this.http
            .get(USER_URL.GET_USER_SCOPE_EDITORS(employeeId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<ScopeEditorModel[]>) => response.data))
            .pipe(take(1));
    }

    postScopeEditor(scopeEditorUser: string): Observable<void> {
        const body = {
            scopeEditorUser,
        };

        return this.http
            .post(SCOPE_EDITOR_URL.POST_SCOPE_EDITOR(), body)
            .pipe(
                catchError(err =>
                    this.systemMessageService.notifyErrorAndThrow(err, err.error.errors[0].message)
                )
            );
    }

    deleteScopeEditor(scopeEditorId: number): Observable<void> {
        return this.http
            .delete(SCOPE_EDITOR_URL.DELETE_SCOPE_EDITOR(scopeEditorId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }

    deleteAllScopeEditors(employeeId: string): Observable<void> {
        return this.http
            .delete(USER_URL.DELETE_ALL_SCOPE_EDITORS(employeeId))
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)));
    }
}

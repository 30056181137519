import { Injectable } from '@angular/core';
import { NOTIFICATION_URL } from '@app/core/constants/urls/notification.url';
import { NoticeModalService } from '@app/modules/notice/services/notice-modal.service';
import { ApiResult } from '@app/shared/models/api-result.model';
import { NoticeModalModel } from '@app/shared/models/notice.model';
import { AuthService } from '@app/shared/services/auth.service';
import { SystemMessageService } from '@app/shared/services/system-message.service';
import { TranslateService } from '@app/shared/services/translate.service';
import {
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
} from '@microsoft/signalr';
import { NotificationActionService } from './notification-action.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationSocketService {
    connection: HubConnection;

    constructor(
        private systemMessageService: SystemMessageService,
        private notificationActionService: NotificationActionService,
        private noticeModalService: NoticeModalService,
        private translateService: TranslateService,
        private authService: AuthService
    ) {}

    async setup() {
        if (this.connection && this.connection.state === HubConnectionState.Connected) return;

        // setup connection
        this.connection = new HubConnectionBuilder()
            .withUrl(NOTIFICATION_URL.NOTIFICATIONS_NEGOTIATE())
            .configureLogging(LogLevel.None)
            .withAutomaticReconnect()
            .build();

        // connect
        this.connection
            .start()
            .catch(e => console.log(e))
            .then(() => this.authenticate());

        // reconnect
        this.connection.onreconnected(() => this.authenticate());

        // subscribe to receive new notifications
        this.connection.on('new-notification', (notifications: string[]) => {
            this.translateService.getTranslation('NOTIFICATION.clickMessage').then(clickMessage => {
                notifications.forEach(n => {
                    this.systemMessageService.notifyInfo(`${n} - ${clickMessage}`);
                });
            });
            this.notificationActionService.getNewNotificationList();
        });

        this.connection.on('new-notice', (notices: NoticeModalModel[]) => {
            this.noticeModalService.showNoticesModal(notices);
            this.notificationActionService.getNewNotificationList();
        });
    }

    // try authenticate user after connection is established
    authenticate() {
        if (!this.authService.isLoggedIn) return;

        const token = `Bearer ${this.authService.getToken()}`;

        if (token) {
            this.connection
                .invoke<ApiResult<boolean>>(
                    'authenticate',
                    this.authService.getEmployeeId(),
                    token,
                    new Date().getTimezoneOffset() / -60,
                    localStorage.getItem('lang') ? localStorage.getItem('lang') : 'pt'
                )
                .then(r => {
                    if (r.data) console.log('authenticate-response-success', r);
                    else {
                        console.log('authenticate-response-error', r);
                    }
                });
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from '@app/shared/services/auth.service';
import { MessageService } from 'primeng/api';

import { NotificationSocketService } from '@app/modules/notification/services/notification-socket.service';
import { RouteAccessLogService } from '@app/shared/services/api/route-access-log.service';
import { MessageTypes } from '@utils/enums/message-types.enum';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-login-callback',
    templateUrl: './login-callback.component.html',
    styleUrls: ['./login-callback.component.scss'],
})
export class LoginCallbackComponent implements OnInit {
    constructor(
        private router: Router,
        private messageService: MessageService,
        private routeAccessLogService: RouteAccessLogService,
        private authService: AuthService,
        private notificationSocketService: NotificationSocketService
    ) {}

    ngOnInit() {
        (async () => await this.finishLoginProcedures())();
    }

    async finishLoginProcedures() {
        setTimeout(() => {
            if (!this.authService.isLoggedIn) {
                this.authService.login();
                return;
            }

            try {
                this.router.events
                    .pipe(filter(event => event instanceof NavigationEnd))
                    .subscribe((event: NavigationEnd) => {
                        const route = event.url;
                        const user = this.authService.getEmployeeId();
                        this.routeAccessLogService.add({ route, user }).subscribe();
                    });

                const currentRoute = this.authService.getReturnUrl();
                if (currentRoute && currentRoute !== '/') {
                    this.authService.clearReturnUrl();
                    this.router.navigate([currentRoute]);
                } else this.router.navigate(['/']);
            } catch (e) {
                this.handleError(e, 'There was an error processing the login request');
                this.router.navigate(['/error']);
            }
        }, 2000);
    }

    handleError(exception, message) {
        if (exception.type !== 'invalid_nonce_in_state')
            return this.messageService.add({
                severity: MessageTypes.Error,
                summary: 'Login Error',
                detail: message,
            });

        this.router.navigate(['/account/login'], { state: { exception } });
    }
}

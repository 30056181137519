import { environment } from '@environment/environment';

const BASE_SCOPE_EDITOR_URL = `${environment.apiUrl}/scope-editor`;

const POST_SCOPE_EDITOR = () => `${BASE_SCOPE_EDITOR_URL}`;

const DELETE_SCOPE_EDITOR = (scopeEditorId: number) => `${BASE_SCOPE_EDITOR_URL}/${scopeEditorId}`;

export const SCOPE_EDITOR_URL = {
    POST_SCOPE_EDITOR,
    DELETE_SCOPE_EDITOR,
};

<app-toolbar-container>
    <div left>
        <app-breadcrumb></app-breadcrumb>
    </div>
</app-toolbar-container>

<app-content-container>
    <p-tabView styleClass="tabview-custom" (onChange)="changeTabView($event.index)">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-cog icon-tab"> </i>
                <span>{{ 'SETTINGS_PAGE.DefaultAlternate' | translate }}</span>
            </ng-template>
            <div
                class="form-container"
                [ngClass]="{
                    'd-none': isLoading
                }">
                <form
                    novalidate="novalidate"
                    #form
                    [formGroup]="withoutAlternateEmployeeForm"
                    (submit)="submitWithoutAlternateEmployeeForm()">
                    <div class="form-without-alternative-employee">
                        <div class="header">
                            <span class="font-label-lg">{{
                                'SETTINGS_PAGE.DeputyForOperations' | translate
                            }}</span>
                            <label class="warning">
                                <i class="pi pi-info-circle"></i>
                                {{ 'SETTINGS_PAGE.WarningAlternate' | translate }}
                            </label>
                        </div>
                        <div class="row">
                            <div class="form-group without-alternate-employee">
                                <p-checkbox
                                    id="without-alternate-employee-checkbox"
                                    [formControl]="
                                        withoutAlternateEmployeeForm.controls[
                                            'withoutAlternateEmployee'
                                        ]
                                    "
                                    [binary]="true"
                                    (onChange)="onCheckWithoutAlternateEmployeeHandler($event)">
                                </p-checkbox>
                                <label id="without-alternate-employee-label">{{
                                    'SETTINGS_PAGE.DefaultAlternateRequired' | translate
                                }}</label>
                                <i
                                    class="pi pi-question-circle"
                                    [pTooltip]="
                                        'SETTINGS_PAGE.DefaultAlternateRequiredMessage' | translate
                                    "
                                    hideDelay="1000">
                                </i>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                withoutAlternateEmployeeForm.controls['withoutAlternateEmployee']
                                    .value
                            "
                            class="row">
                            <div class="field">
                                <button
                                    pButton
                                    pRipple
                                    type="button"
                                    [label]="'DEFAULT_TEXT.Save' | translate"
                                    class="b-green"
                                    [disabled]="
                                        !withoutAlternateEmployeeForm.controls[
                                            'withoutAlternateEmployee'
                                        ].value
                                    "
                                    (click)="submitWithoutAlternateEmployeeForm()"></button>
                            </div>
                        </div>
                    </div>
                </form>

                <form
                    *ngIf="!withoutAlternateEmployeeForm.controls['withoutAlternateEmployee'].value"
                    novalidate="novalidate"
                    #form
                    [formGroup]="alternateEmployeeForm"
                    (submit)="submitAlternateEmployeeForm()">
                    <div class="form-alternative-employee">
                        <div class="row">
                            <div class="field">
                                <span for="employeeId" class="field-label">
                                    {{ 'DEFAULT_TEXT.Registration' | translate }} <span>*</span>
                                </span>
                                <p-autoComplete
                                    id="alternate-employeeId-input"
                                    [suggestions]="employees"
                                    (completeMethod)="searchEmployeeId($event.query)"
                                    formControlName="employeeId"
                                    (onSelect)="onSelectEmployeeId($event.value)"
                                    field="employeeID"
                                    [autoHighlight]="true"
                                    [forceSelection]="true"
                                    minLength="3"
                                    maxlength="50"
                                    [disabled]="
                                        withoutAlternateEmployeeForm.controls[
                                            'withoutAlternateEmployee'
                                        ].value
                                    "
                                    [ngClass]="{
                                        'alternate-employee-input-invalid':
                                            withoutAlternateEmployeeForm.invalid &&
                                            withoutAlternateEmployeeForm.dirty
                                    }">
                                </p-autoComplete>
                            </div>
                            <div class="field">
                                <span for="name" class="field-label">
                                    {{ 'DEFAULT_TEXT.Name' | translate }} <span>*</span>
                                </span>

                                <p-autoComplete
                                    id="alternate-name-input"
                                    [suggestions]="employees"
                                    (completeMethod)="searchName($event.query)"
                                    formControlName="name"
                                    (onSelect)="onSelectName($event.value)"
                                    field="name"
                                    [autoHighlight]="true"
                                    [forceSelection]="true"
                                    minLength="3"
                                    maxlength="50"
                                    [disabled]="
                                        withoutAlternateEmployeeForm.controls[
                                            'withoutAlternateEmployee'
                                        ].value
                                    "
                                    [ngClass]="{
                                        'alternate-employee-input-invalid':
                                            withoutAlternateEmployeeForm.invalid &&
                                            withoutAlternateEmployeeForm.dirty
                                    }">
                                </p-autoComplete>
                            </div>
                            <div class="button-content">
                                <button
                                    pButton
                                    pRipple
                                    type="button"
                                    [label]="'DEFAULT_TEXT.Add' | translate"
                                    class="b-green"
                                    [disabled]="
                                        alternateEmployeeForm.invalid ||
                                        !alternateEmployeeForm.dirty
                                    "
                                    (click)="submitAlternateEmployeeForm()"></button>
                            </div>
                        </div>
                    </div>
                </form>

                <div
                    class="table-content"
                    [ngClass]="{
                        'd-none':
                            withoutAlternateEmployeeForm.controls['withoutAlternateEmployee'].value
                    }">
                    <div class="row">
                        <app-global-table-unpaged
                            [originalCols]="tableColumns"
                            [data]="registeredAlternates"
                            (removeAll)="setWithoutAlternate()"
                            (removeItem)="removeAlternate($event)">
                        </app-global-table-unpaged>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-search icon-tab"></i>
                <span>{{ 'SETTINGS_PAGE.SearchTabTitle' | translate }}</span>
            </ng-template>
            <div class="form-container">
                <form novalidate="novalidate" #form [formGroup]="alternateEmployeeSearchForm">
                    <span class="font-label-lg">{{
                        'SETTINGS_PAGE.SearchDefaultAlternate' | translate
                    }}</span>
                    <div class="">
                        <div class="row">
                            <div class="field">
                                <span for="employeeId" class="field-label">
                                    {{ 'DEFAULT_TEXT.Registration' | translate }} <span>*</span>
                                </span>
                                <p-autoComplete
                                    id="alternate-employeeIdSearch-input"
                                    [suggestions]="employees"
                                    (completeMethod)="searchEmployeeId($event.query)"
                                    (onSelect)="onSelectEmployeeId($event.value, true)"
                                    field="employeeID"
                                    formControlName="employeeIdSearch"
                                    [autoHighlight]="true"
                                    [forceSelection]="true"
                                    minLength="3"
                                    maxlength="50">
                                </p-autoComplete>
                            </div>
                            <div class="field">
                                <span for="name" class="field-label">
                                    {{ 'DEFAULT_TEXT.Name' | translate }} <span>*</span>
                                </span>

                                <p-autoComplete
                                    id="alternate-nameSearch-input"
                                    [suggestions]="employees"
                                    (completeMethod)="searchName($event.query)"
                                    (onSelect)="onSelectName($event.value, true)"
                                    field="name"
                                    formControlName="nameSearch"
                                    [autoHighlight]="true"
                                    [forceSelection]="true"
                                    minLength="3"
                                    maxlength="50">
                                </p-autoComplete>
                            </div>
                        </div>

                        <div class="form-group">
                            <label
                                ><strong
                                    >{{ 'SETTINGS_PAGE.DefaultAlternate' | translate }}:
                                </strong></label
                            >
                            <p *ngIf="alternateSearchMessage !== ''">
                                {{ alternateSearchMessage }}
                            </p>
                        </div>

                        <div class="row" *ngIf="alternatesSearch.length > 0">
                            <app-global-table-unpaged
                                [originalCols]="tableColumnsSearch"
                                [data]="alternatesSearch">
                            </app-global-table-unpaged>
                        </div>
                    </div>
                </form>
            </div>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-cog icon-tab"> </i>
                <span>{{ 'SETTINGS_PAGE.ScopeEditor.Title' | translate }}</span>
            </ng-template>
            <div
                class="form-container"
                [ngClass]="{
                    'd-none': scopeEditorIsLoading
                }">
                <div id="withoutScopeEditor">
                    <div class="form-without-alternative-employee">
                        <div class="header">
                            <span class="font-label-lg">{{
                                'SETTINGS_PAGE.ScopeEditor.Subtitle' | translate
                            }}</span>
                            <label class="warning">
                                <i class="pi pi-info-circle"></i>
                                {{ 'SETTINGS_PAGE.ScopeEditor.WarningUserAccessed' | translate }}
                            </label>
                        </div>
                        <div class="row">
                            <div class="form-group without-alternate-employee">
                                <p-checkbox
                                    id="without-scope-editor-checkbox"
                                    [(ngModel)]="withoutScopeEditor"
                                    [binary]="true">
                                </p-checkbox>
                                <label id="without-scope-editor-label">{{
                                    'SETTINGS_PAGE.ScopeEditor.WithoutScopeEditor' | translate
                                }}</label>
                            </div>
                        </div>
                        <div
                            *ngIf="
                                withoutScopeEditor &&
                                registeredScopeEditors &&
                                registeredScopeEditors.length > 0
                            "
                            class="row">
                            <div class="field">
                                <button
                                    pButton
                                    pRipple
                                    type="button"
                                    id="without-scope-editor-button"
                                    [label]="'DEFAULT_TEXT.Save' | translate"
                                    class="b-green"
                                    (click)="clearScopeEditors()"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <form
                    *ngIf="!withoutScopeEditor"
                    novalidate="novalidate"
                    #form
                    [formGroup]="scopeEditorForm"
                    (submit)="submitScopeEditorForm()">
                    <div class="form-alternative-employee">
                        <div class="row">
                            <div class="field">
                                <span for="employeeId" class="field-label">
                                    {{ 'DEFAULT_TEXT.Registration' | translate }} <span>*</span>
                                </span>
                                <p-autoComplete
                                    id="scope-editor-employeeId-input"
                                    [suggestions]="employees"
                                    (completeMethod)="searchEmployeeId($event.query)"
                                    formControlName="employeeId"
                                    field="employeeID"
                                    (onSelect)="onSelectEmployeeId($event.value)"
                                    [autoHighlight]="true"
                                    [forceSelection]="true"
                                    minLength="3"
                                    maxlength="50">
                                </p-autoComplete>
                            </div>
                            <div class="field">
                                <span for="name" class="field-label">
                                    {{ 'DEFAULT_TEXT.Name' | translate }} <span>*</span>
                                </span>

                                <p-autoComplete
                                    id="scope-editor-name-input"
                                    [suggestions]="employees"
                                    (completeMethod)="searchName($event.query)"
                                    formControlName="name"
                                    field="name"
                                    (onSelect)="onSelectName($event.value)"
                                    [autoHighlight]="true"
                                    [forceSelection]="true"
                                    minLength="3"
                                    maxlength="50">
                                </p-autoComplete>
                            </div>
                            <div class="button-content">
                                <button
                                    pButton
                                    pRipple
                                    type="submit"
                                    id="add-scope-editor-button"
                                    [label]="'DEFAULT_TEXT.Add' | translate"
                                    class="b-green"></button>
                            </div>
                        </div>
                    </div>
                </form>

                <div
                    class="table-content"
                    [ngClass]="{
                        'd-none': withoutScopeEditor
                    }">
                    <div class="row">
                        <app-global-table-unpaged
                            [originalCols]="scopeEditorTableColumns"
                            [data]="registeredScopeEditors"
                            (removeAll)="clearScopeEditors()"
                            (removeItem)="removeScopeEditor($event)">
                        </app-global-table-unpaged>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</app-content-container>

import { Injectable } from '@angular/core';
import { AUTH_URL } from '@app/core/constants/urls/auth.url';
import { USER_URL } from '@app/core/constants/urls/user.url';
import { HttpClient } from '@app/core/utils/helpers/HttpClient';
import { ApiResult } from '@app/shared/models/api-result.model';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { SystemMessageService } from '../system-message.service';

@Injectable({
    providedIn: 'root',
})
export class UserProfileService {
    constructor(
        private http: HttpClient,
        private systemMessageService: SystemMessageService
    ) {}

    getFakeLoginToken(user: string, roles: string[]): Observable<string> {
        return this.http
            .post<ApiResult<string>>(AUTH_URL.GET_FAKE_LOGIN_TOKEN(), { user, roles })
            .pipe(catchError(err => this.systemMessageService.notifyErrorAndThrow(err)))
            .pipe(map((response: ApiResult<string>) => response.data))
            .pipe(take(1));
    }

    logUserAccess(): Observable<ApiResult<any>> {
        return this.http.post(USER_URL.ADD_USER_LOG(), {});
    }
}
